<template>
  <v-app footer>
    <v-main>
      <router-view />
    </v-main>
    <notifications group="global" position="top center"></notifications>
  </v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
export default {
  mounted() {
    //Unregister any service workers that might be here.
    if(navigator.serviceWorker)
    {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {

                registration.unregister()

        }}).catch(function(err) {

        console.log('Service Worker registration failed: ', err);

      });
    }
  }
}
</script>