import axios from 'axios';


export default {
  // components: {betterfetch},
  async fetch (url, options)
  {
    let response = null;

    try
    {
      //Get headers from options, or empty object if none set.
      var headers = options.headers || {};
      var method = options.method || 'GET';
      if (method !== 'GET')
      {
        // var csrf = await this.getCSRFToken().then((response) => response.json());
        // headers['X-CSRF-TOKEN'] = csrf._csrf;
        if (!headers['Content-Type'])
        {
          headers['Content-Type'] = 'application/json';
        }
      }
      options.headers = headers;
      if (process.env.NODE_ENV.toUpperCase() == 'DEVELOPMENT')
      {
        console.log(url);
      }

      response = await fetch(url, options);
      if (response)
      {
        if (response.status == 401 && !window.location.href.includes('/login') && !window.location.href.includes('signup') && !window.location.href.includes('resetpassword'))
        {
          window.location.href = '/login'
        }
      }

    }
    catch (e)
    {
      console.log("SelectFunctions:Fetch:Error : ", e);
    }

    return response;
    //   var promise = new Promise(((resolve, reject) =>
    //   {
    //     fetch(url, options)
    //       // .catch(error =>
    //       // {
    //       //   console.log('Select Fetch : error ');
    //       //   reject(error);
    //       // })
    //       .then(response =>
    //       {
    //         console.log(response.status);
    //         if (response.status == 401)
    //         {
    //           reject('401 ERROR');
    //           if (window.location.href.startsWith('/admin') && !window.location.href.includes('/login'))
    //           {
    //             window.location.href = '/admin/login';
    //           }
    //         }
    //         else
    //         {
    //           resolve(response);
    //         }
    //       })
    //       .catch(error =>
    //       {
    //         console.log('error decoding json' + error);
    //         reject(error);
    //       });
    //   }));
    //   promise.then(async (response) =>
    //   {
    //     let clone = await response.clone();
    //     try
    //     {
    //       let data = clone.response.text();
    //       data = JSON.parse(data);
    //       data = _.filter(data, (val) =>
    //       {
    //         return Object.keys(_.flatMap(val)).length > 2;
    //       });
    //       response.json = function ()
    //       {
    //         return data;
    //       }
    //     }
    //     catch (exception)
    //     {
    //       console.log("Not JSON, ignoring.");
    //       throw `Not JSON, ignoring:', ${ exception }`;
    //       return null;
    //     }
    //   }, (error) =>
    //   {
    //       console.log('SelectFunctions:fetch:reject:', error);
    //       throw `SelectFunctions:fetch:reject:', ${ error }`;
    //   })
    //   .catch(exception => 
    //   {
    //     throw `SelectFunctions:fetch:promise catch:${exception}`
    //   });

    //   return promise;
    // }
    // catch (e)
    // {
    //   console.log('SelectFunctions:Fetch:Caught Error:',e)
    //   return null;
    // }
  },
  checkLoggedIn: function ()
  {
    var promise = new Promise((resolve, reject) =>
    {
      this.fetch(process.env.VUE_APP_BACKEND_ENDPOINT + 'isLoggedIn', {
        credentials: 'include'
      })
        .then(response =>
        {
          if (response.ok)
          {
            console.log('Returned response : ');
            resolve('OK');
          }
          else
          {
            reject('ERROR');
          }
        })
        .catch(error =>
        {
          reject('ERROR');
        });
    });
    promise.then((result) =>
    { }, (error) =>
    { });
    return promise;
  },
  getCSRFToken: function ()
  {
    var promise = new Promise((resolve, reject) =>
    {
      this.fetch(process.env.VUE_APP_BACKEND_ENDPOINT + 'csrfToken', {
        credentials: 'include'
      })
        .then(response =>
        {
          console.log('Returned response : ');
          resolve(response);
        })
        .catch(error =>
        {
          reject('ERROR');
        });
    });
    promise.then((result) =>
    { }, (error) =>
    { });
    return promise;
  },
  serialise: function (obj)
  {
    var str = [];
    for (var p in obj)
    {
      if (obj.hasOwnProperty(p))
      {
        str.push(encodeURIComponent(p) + '='
          + encodeURIComponent(
            (typeof (obj[p]) === 'object' ? JSON.stringify(obj[p]) : obj[p])
          ));
      }
    }
    return str.join('&');
  },
  capitaliseFirst: function (string)
  {
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string;
  },

  async getPopups (passedKey)
  {
    let returnResults = [];
    let results = await this.fetch(process.env.VUE_APP_BACKEND_ENDPOINT + 'popups/' + encodeURIComponent(passedKey), { credentials: 'include' });
    if (results)
    {
      returnResults = await results.json();
    }

    return returnResults;
  },

  async getUsername ()
  {
    let returnUsername = '';
    let results = await this.fetch(process.env.VUE_APP_BACKEND_ENDPOINT + 'users/profile', { credentials: 'include' });

    if (results.ok)
    {
      let userData = await results.json();
      if (userData)
      {
        returnUsername = userData.username;
      }
    }
    return returnUsername;
  },

  async getUserGroup ()
  {
    let returnUserGroup = '';

    let results = await this.fetch(process.env.VUE_APP_BACKEND_ENDPOINT + 'users/profile', { credentials: 'include' });

    if (results.ok)
    {
      let userData = await results.json();
      if (userData)
      {
        returnUserGroup = userData.usergroup;
      }
    }

    return returnUserGroup;

  },

  async getUserProfile ()
  {
    let returnUserProfile = {};
    let results = await this.fetch(process.env.VUE_APP_BACKEND_ENDPOINT + 'users/profile', { credentials: 'include' });

    if (results.ok)
    {
      let userData = await results.json();
      if (userData)
      {
        returnUserProfile = userData;
      }
    }

    return returnUserProfile;
  },

  async saveFormData (formData, savePath)
  {
    let response = null;

    if (!formData._id)
    {
      // add mode
      response = await this.fetch(
        savePath,
        {
          credentials: 'include',
          method: 'PUT',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        }
      )
    }
    else
    {
      // edit mode
      response = await this.fetch(
        savePath,
        {
          credentials: 'include',
          method: 'PATCH',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        }
      );
    }

    return response;
  },

  async deleteRecord (recordData, deletePath)
  {
    let response = null;

    response = await this.fetch(
      deletePath,
      {
        credentials: 'include',
        method: 'DELETE',
        headers:
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(recordData)
      }
    );

    return response;
  },

  async hasUserGroup (groupname)
  {
    let returnValue = false;

    let response = await this.fetch(
      process.env.VUE_APP_BACKEND_ENDPOINT + 'users/myusergroups',
      {
        credentials: 'include'
      }
    );

    if (response && response.ok)
    {
      let usergroups = await response.json();
      let check = usergroups.filter(record => record.name == groupname);
      console.log('hasUserGroup : ', check)
      if (check && check.length)
      {
        returnValue = true;
      }
    }

    return returnValue;
  },

  hoursToHoursAndMinutes (hours = 0)
  {
    let returnValue = { hours: 0, minutes: 0 };

    if (hours)
    {
      let returnHours = Math.floor(hours);
      let fractionHour = hours - returnHours;
      let returnMinutes = Number((fractionHour * 60).toFixed(2));

      returnValue.hours = returnHours;
      returnValue.minutes = returnMinutes;

    }


    return returnValue;
  },

  hoursFromHoursAndMinutes (hours = 0, minutes = 0)
  {
    let returnValue = 0.00;

    returnValue = hours + Number((minutes / 60).toFixed(4));
    returnValue = returnValue;

    return returnValue;


  },
  
  async getSetting (passedKey)
  {
    let returnResult = null;

    let response = await this
      .fetch(
        process.env.VUE_APP_BACKEND_ENDPOINT + 'settings/'
        + encodeURIComponent(passedKey),
        { credentials: 'include' }
      );

    if (response)
    {
      try
      {
        returnResult = await response.json();
      }
      catch (e)
      {
        returnResult = null;
      }
    }

    return returnResult;
  },
};
