import Vue from 'vue'
import VueRouter from 'vue-router'

import SelectFunctions from '@/select/SelectFunctions';

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',

        meta: {
            title: 'Home',
        },

        component: () =>
            import ( /* webpackChunkName: "landing" */ '../views/Landing.vue')
    },
    {
        path: '/signup/:base64',
        name: 'Signup',

        meta: {
            title: 'Sign Up',
        },

        component: () =>
            import ( /* webpackChunkName: "signup" */ '../views/SignUp.vue')
    },
    {
        path: '/login',
        name: 'Login',

        meta: {
            title: 'Login',
        },

        component: () =>
            import ( /* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/login/:email',
        name: 'Login',

        meta: {
            title: 'Login',
        },

        component: () =>
            import ( /* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/statistics',
        name: 'Statistics',

        meta: {
            title: 'Statistics',
        },

        component: () =>
            import ( /* webpackChunkName: "landing" */ '../views/Statistics.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    document.title = (process.env.VUE_APP_APP_TITLE || '').concat(' - '.concat(to.meta.title));
    SelectFunctions.checkLoggedIn()
        .then(result => {
            console.log('checkLoggedIn result : ');
            console.log(result);
            if (result == 'OK') {
                next();
            }
        })
        .catch(error => {
            console.log('Check logged in error result : ');
            console.log(error);
            console.log("To Name:", to.name);
            console.log(to.name.toUpperCase() != 'SIGNUP');
            if (to.name.toUpperCase() != 'LOGIN' && to.name.toUpperCase() != 'SIGNUP' && to.name.toUpperCase() != 'FORGOTPASSWORD' && to.name.toUpperCase() != 'RESETPASSWORD') {
                console.log("Redirecting to login!");
                next('/login');
            } else {
                next(); // user is going to the login page anyway
            }
        });
});

export default router